<template>
  <b-card v-if="ready" title="Bookings calendar">
    <ListCalendarSwitch active="calendar" />
    <b-form-group label-cols-sm="4" label-cols-lg="3" label="Start">
      <b-form-select v-model="startPeriod" :options="periodOptions" @input="startChanged" />
    </b-form-group>
    <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
      <blueprint-search-input
        v-model="propertyId"
        :multiple="true"
        placeholder="Type to search Properties"
        label="name"
        track-by="id"
        api-route-path="inventory/properties"
      />
    </b-form-group>
    <b-overlay :show="loading" rounded="sm">
      <b-row id="timeline">
        <b-col cols="3">
          <div class="header" />
          <div v-for="unit in units" :key="unit.id" class="line unitname">
            <div>{{ unit.property.name }}</div><div> • {{ unit.name }}</div>
          </div>
        </b-col>
        <b-col cols="9">
          <div class="scrollable" @scroll="handleScroll">
            <div class="header">
              <div class="months">
                <div v-for="(x, m) in getMonts()" :key="x">
                  <div class="calmonth" :class="{odd: m % 2 === 0}" :style="{ width: `${getDays(m) * DAYREM}rem` }">
                    {{ getMonth(m + start.getMonth() + 1, start.getFullYear() + Math.floor(m / 12)) }}
                  </div>
                </div>
              </div>
              <div class="days">
                <div v-for="(x, m) in getMonts()" :key="x" :class="{odd: m % 2 === 0}" class="daysmonth">
                  <div v-for="(y, d) in getDays(m + start.getMonth() + 1)" :key="y" :style="{ width: `${DAYREM}rem`, minWidth: `${DAYREM}rem`, maxWidth: `${DAYREM}rem` }" class="calday">
                    {{ getDay(m + start.getMonth() + 1, d) }}
                  </div>
                </div>
              </div>
            </div>
            <div v-for="unit in units" :key="unit.id" class="line">
              <div
                v-for="booking in bookings[unit.id]"
                :key="booking.id"
                :class="{
                  info: booking.status === 'NEW',
                  warning: booking.status === 'CONFIRMED',
                  danger: booking.status === 'CANCELED',
                  dark: booking.status === 'CLOSED',
                  success: booking.status === 'ACTIVATED',
                }"
                class="booking"
                :style="booking.style"
              >
                <router-link :to="{ name: 'bookings.booking.edit', params: { id: booking.id } }">
                  {{ booking.firstname }} {{ booking.lastname }}
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<style lang="scss" scoped>
@import 'larva/_variables.scss';
@import 'larva/functions.scss';
@import 'larva/mixins.scss';

  #timeline {
    position: relative;
  }
  .unitname div {
    width: 50%;
    display: inline-block;
  }
  .unitname div:first-child {
    text-align: right;
    padding-right: .3rem;
  }
  .unitname div, .booking {
    height: 1.4rem;
    line-height: 1.5rem;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .calmonth{
    min-width: 2rem;
    height: 2rem;
  }

  .calday{
    height: 2rem;
    font-size: .9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .header {
    height: 4rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .scrollable {
    overflow-x: scroll;
    padding-bottom: 2rem;
    text-align: center;
    font-weight: 500;
  }
  .scrollable .header .days, .scrollable .header .daysmonth, .scrollable .header .months {
    display: flex;
  }
  .line {
    height: 2rem;
    position: relative;
  }
  .booking {
    position: absolute;
    color: lar-color(primary, contrast);
    background: lar-color(primary, base);
    @include border-radius(.4rem);
    @include padding(0, .2rem, 0, .2rem);
  }
  .booking.info, .booking.info a {
    color: lar-color(tertiary, contrast);
    background: lar-color(tertiary, base);
  }
  .booking.warning, .booking.warning a {
    color: lar-color(warning, contrast);
    background: lar-color(warning, base);
  }
  .booking.danger, .booking.danger a {
    color: lar-color(danger, contrast);
    background: lar-color(danger, base);
  }
  .booking.dark,  .booking.dark a {
    color: lar-color(dark, contrast);
    background: lar-color(dark, base);
  }
  .booking.success, .booking.success a {
    color: lar-color(success, contrast);
    background: lar-color(success, base);
  }
   .booking a:hover {
    text-decoration: none;
  }
  .booking a {
    display: block;
  }
  .calmonth {
    font-weight: bold;
  }
  .calmonth.odd {
    background-color: lar-color(light, base);
    color:  lar-color(light, contrast);
  }
  .daysmonth {
    background-color: lar-color(light, base);
    color:  lar-color(light, contrast);
  }
  .daysmonth.odd {
    background-color: $background-color;
    color:  $text-color;
  }
  .line {
    border-top: 1px solid lar-color(light, base);
    padding: .2rem 0;
  }
</style>
<script>
// TODO: add hover info for booking (eg. crate custom component)
const DAYREM = 1.8;
const MONTHSLOAD = 2;

import * as moment from 'moment';
import * as _ from 'lodash';
import utils from '../../../libs/utils.vue';
import ListCalendarSwitch from './components/list-clendar-switcher.vue';

export default {
  components: {
    ListCalendarSwitch
  },
  data () {
    return {
      start: null,
      end: null,
      DAYREM,
      propertyId: null,
      status: ['NEW', 'CONFIRMED', 'CLOSED', 'ACTIVATED'],
      errors: {},
      bookings: [],
      units: [],
      periodOptions: [],
      startPeriod: null,
      loading: false,
      ready: false,
    };
  },
  watch: {
    propertyId () {
      this.getBookings();
    },
    end () {
      this.getBookings();
    },
    start () {
      this.end = new Date(this.start);
      this.end.setMonth(this.end.getMonth() +1);
      this.getBookings();
    }
  },
  mounted () {
    const now = new Date();
    for (let y = now.getFullYear() - 5; y <= now.getFullYear() + 1; y++) {
      for (let m = 0; m < 12; m++) {
        const period = moment().set({ month: m, year: y });
        this.periodOptions.push({
          value: {
            month: m,
            year: y,
          },
          text: `${period.format('YYYY MMMM')}`
        });
      }
    }
    this.startPeriod = {
      month: now.getMonth(),
      year: now.getFullYear(),
    };
    this.start = moment(now).startOf('month').toDate();
    this.end = now;
    this.end.setMonth(this.end.getMonth() + MONTHSLOAD);
    this.getBookings();
  },
  methods: {
    startChanged () {
      const newStart = new Date();
      newStart.setFullYear(this.startPeriod.year);
      newStart.setMonth(this.startPeriod.month);
      this.start = newStart;
    },
    handleScroll (el) {
      const { target } = el;
      const pos = target.offsetWidth + target.scrollLeft;
      const end = target.scrollWidth - 200;
      if (pos >= end) {
        this.end.setMonth(this.end.getMonth() + MONTHSLOAD);
        this.getBookings();
      }
    },
    convertRangeDate (inDate) {
      return moment(inDate);
    },
    getDay (month, day) {
      return moment().utc().set({ month: (month - 1), date: (day+1) }).format('DD');
    },
    getDays (month) {
      const date = moment().utc().set({ month: (month - 1) });
      return date.daysInMonth();
    },
    getMonts () {
      const start = moment(this.start);
      const end = moment(this.end);
      return end.diff(start, 'months', false) || MONTHSLOAD;
    },
    getMonth (month, year) {
      return moment().utc().set({ month: (month - 1), year }).format('YYYY MMMM');
    },
    getBookingStyle (booking) {
      const rangeStart = moment(booking.range.start).utc().endOf('day');
      const rangeEnd = moment(booking.range.end).utc().startOf('day');
      const start = moment(this.start);
      const end = moment(this.end);
      let calculatedWidth = Math.ceil(rangeEnd.diff(rangeStart, 'days', true));
      let calculatedLeft = Math.ceil(rangeStart.diff(start, 'days', true));
      if (rangeStart.isBefore(start)) {
        calculatedWidth = Math.round(rangeEnd.diff(start, 'days', true));
        calculatedLeft = 0;
      }
      if (rangeEnd.isAfter(end)) {
        calculatedWidth = calculatedWidth - Math.ceil(rangeEnd.diff(end, 'days', true));
      }
      const style = {
        left: calculatedLeft * DAYREM - DAYREM/2 + 'rem',
        width: calculatedWidth * DAYREM - DAYREM/20 + 'rem',
      };
      if (rangeStart.isBefore(start)) { // start is befor our start
        style['border-top-left-radius'] = 0;
        style['border-bottom-left-radius'] = 0;
      }
      if (rangeEnd.isAfter(end)) {
        style['border-top-right-radius'] = 0;
        style['border-bottom-right-radius'] = 0;
      }
      return style;
    },
    async getBookings () {
      try {
        if (this.loading === true) {
          return;
        }
        this.loading = true;
        const overlaps = await this.getAll('bookings', {
          params: {
            where: {
              range: {
                '$overlap': [this.start, this.end]
              },
              ...utils.parseWhereItem('unit.propertyId', this.propertyId),
              ...utils.parseWhereItem('status', this.status),
            }
          }
        });
        const between = await this.getAll('bookings', {
          params: {
            start: this.start,
            end: this.end,
            where: {
              ...utils.parseWhereItem('unit.propertyId', this.propertyId),
              ...utils.parseWhereItem('status', this.status),
            }
          }
        });
        const data = [...overlaps.data, ...between.data];
        this.bookings = _.chain(data)
          .uniqBy('id')
          .map((booking) => {
            booking.unitId = booking.unit.id;
            booking.style = this.getBookingStyle(booking);
            delete booking.unit;
            return booking;
          })
          .groupBy('unitId')
          .value();
        await this.getUnits();
      } catch (err) {
        this.err(err);
      } finally {
        this.loading = false;
        this.ready = true;
      }
    },
    getUnits () {
      return this.getAll('inventory/units', {
        params: {
          where: {
            ...utils.parseWhereItem('propertyId', this.propertyId),
          },
          order: 'name'
        }
      })
      .then(({ data }) => {
        this.units = data;
      });
    },
    async getAll (url, params) {
      const limit = 10000; // max API limit
      let returnData = [];
      let count = 1;
      let returned = 0;
      let start = 0;
      while (count > returned) {
        const { headers, data } = await this.$http.get(url, {
          ...params,
          start,
          limit
        });
        start += limit;
        count = parseInt(headers['x-total-count'] || 0, 10);
        returned = Array.isArray(data) ? data.lenght : 0;
        returnData = [...returnData, ...data];
      }
      return { data: returnData };
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
  },
};
</script>
